import { Injectable } from '@angular/core';
import { ApiService } from '../../api-service/api.service';
import { RequestHeadersType } from '@monsido/ng2/external/http/options';

type PagePerformanceType = {
    page_id: number;
    profile_id: number;
    status: string;
    created_at: string;
    error: string | null
}

type PagePerformanceCreateType = {
    page_id: number;
    profile_id: number;
};

@Injectable({
    providedIn: 'root',
})
export class PagePerformanceRescanRepoService {

    constructor (private apiService: ApiService) { }

    get (params: PagePerformanceCreateType, headers?: RequestHeadersType): Promise<PagePerformanceType> {
        return this.apiService.get('performance/scans', params, headers);
    }

    create (params: PagePerformanceCreateType, headers?: RequestHeadersType): Promise<PagePerformanceType> {
        return this.apiService.post('performance/scans', params, headers);
    }
}
