import { Injectable } from '@angular/core';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { ApiService } from '../api-service/api.service';
import { DemoStatsRepoService } from '@client/app/modules/demodata/endpoints/pages/demo-stats-repo/demo-stats-repo.service';
import { StatisticsEntryPageModel } from './statistics-entry-page-model';
import { StatisticsCity, StatisticsLocation, StatisticsPage, StatisticsRegion, StatisticsVisitor } from '@client/app/modules/statistics/interfaces/statistics-vistor.interface';
import { PageModel } from '@client/app/models/page-model-factory.service';
import { MonTableCollection } from '@monsido/ng2/models/table-collection.interface';
import { StatisticsDownload } from '@client/app/modules/statistics/interfaces/statistics-download.interface';
import { StatisticsKeyword } from '@client/app/modules/statistics/interfaces/statistics-keyword.interface';
import { StatisticsSummary } from '@client/app/modules/statistics/interfaces/statistics-summary.interface';
import { StatisticsSiteSearchKeyword } from '@client/app/modules/statistics/interfaces/statistics-site-search-keyword.interface';
import { CustomerHasPlanPipe } from '@monsido/ng2/shared/pipes/customer-has-plan/customer-has-plan.pipe';
import { KeywordsStatisticsResult } from '@monsido/ng2/angularjs-providers/stats-repo/stats-repo.types';
import { RequestHeadersType, RequestParamsType } from '@monsido/ng2/external/http/options';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';

type StateRepoRequestType = RequestParamsType & BaseRepoRequestType;
@Injectable({
    providedIn: 'root',
})
export class StatsRepoService extends BaseRepo {

    constructor (
        protected sessionService: SessionService,
        private apiService: ApiService,
        private demoStatsRepoService: DemoStatsRepoService,
        private customerHasPlanPipe: CustomerHasPlanPipe,
    ) {
        super();
    }

    getResolutions (params: StateRepoRequestType = {}, config: RequestHeadersType = {}): Promise<StatisticsVisitor[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getResolutions();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/resolutions', params, config);
        }
    }

    getOs (params: StateRepoRequestType, config: RequestHeadersType): Promise<StatisticsVisitor[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getOs();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/os', params, config);
        }
    }

    getOrganizations (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsVisitor[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getOrganizations();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/organizations', params, config);
        }
    }

    getLocations (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsLocation[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getLocations();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/locations', params, config);
        }
    }

    getRegions (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsRegion[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getRegions();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/regions', params, config);
        }
    }

    getCities (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsCity[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getCities();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/cities', params, config);
        }
    }

    getLanguages (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsVisitor[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getLanguages();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/languages', params, config);
        }
    }

    getBrowsers (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsVisitor[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getBrowsers();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/browsers', params, config);
        }
    }

    getSearchEngines (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsVisitor[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getSearchEngines();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/search_engines', params, config);
        }
    }

    getReferrers (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsVisitor[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getReferrers();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/referrers', params, config);
        }
    }

    getPagesWithoutVisits (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<MonTableCollection<PageModel>> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getPagesWithoutVisits(params);
        } else {
            params = this.setGroupId(params);
            return this.apiService.get(
                'domains/' + this.getDomainId(params.domain_id) + '/statistics/pages_without_visits', params, config);
        }
    }

    async getPagesDocumentsWithoutDownloads (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<PageModel[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getPagesDocumentsWithoutDownloads(params);
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' +
                this.getDomainId(params.domain_id) +
                '/statistics/documents_without_downloads', params, config);
        }
    }

    async getDownloadsByUrl (params: StateRepoRequestType = {}): Promise<StatisticsDownload[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getDownloadsByUrl(params);
        }
        params = this.setGroupId(params);
        return this.apiService.post('domains/' + this.getDomainId(params.domain_id) + '/statistics/downloads_by_url', params);
    }

    getPagesByUrl (params:StateRepoRequestType = {}): Promise<StatisticsPage[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getPagesByUrl(params);
        }
        params = this.setGroupId(params);
        return this.apiService.post('domains/' + this.getDomainId(params.domain_id) + '/statistics/pages_by_url', params);
    }

    getLeastPopularPages (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<MonTableCollection<StatisticsPage>> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getLeastPopularPages(params);
        } else {
            params = this.setGroupId(params);
            return this.apiService.get(
                'domains/' + this.getDomainId(params.domain_id) + '/statistics/least_popular_pages', params, config);
        }
    }

    getExitPages (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<MonTableCollection<StatisticsPage>> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getExitPages(params);
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/exit_pages', params, config);
        }
    }

    getEntryPages (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<MonTableCollection<StatisticsPage>> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getEntryPages(params);
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/entry_pages', params, config)
                .then(
                    (res: MonTableCollection<StatisticsPage>) => this.toEntryPageModels(res),
                );
        }
    }

    getKeywords (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsKeyword[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getKeywords();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/keywords', params, config);
        }
    }

    getMostPopularPages (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<MonTableCollection<StatisticsPage>> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getMostPopularPages(params);
        } else {
            params = this.setGroupId(params);
            return this.apiService.get(
                'domains/' + this.getDomainId(params.domain_id) + '/statistics/most_popular_pages', params, config,
            );
        }
    }

    getMostDownloads (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsDownload[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getMostDownloads(params);
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/downloads', params, config);
        }
    }

    getSummary (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsSummary> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getSummary();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/summary', params, config);
        }
    }

    getSummaryPerDate (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsSummary[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getSummaryPerDate(params);
        } else {
            params = this.setGroupId(params);
            return this.apiService.get(
                'domains/' + this.getDomainId(params.domain_id) + '/statistics/summary_per_date', params, config,
            );
        }
    }

    getVisitors (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<{
        referring: StatisticsVisitor[];
        direct: StatisticsVisitor[];
        social: StatisticsVisitor[];
        organic: StatisticsVisitor[];
        paid: StatisticsVisitor[];
    }> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getVisitors(params);
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/visitors', params, config);
        }
    }

    getDevices (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsVisitor[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getDevices();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/devices', params, config);
        }
    }

    getSocials (params: StateRepoRequestType = {}, config:RequestHeadersType = {}): Promise<StatisticsVisitor[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getSocials();
        } else {
            params = this.setGroupId(params);
            return this.apiService.get('domains/' + this.getDomainId(params.domain_id) + '/statistics/socials', params, config);
        }
    }

    getSiteSearchKeywords (params: StateRepoRequestType = {}, config = {}): Promise<MonTableCollection<KeywordsStatisticsResult>> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getSiteSearchKeywords() as unknown as Promise<MonTableCollection<KeywordsStatisticsResult>>;
        }
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId(params.domain_id)}/statistics/site_search/keywords`, params,
            {
                headers: {
                    noParseSearch: 'true',
                    ...config,
                },
            });
    }

    getSiteSearchNoResultsKeywords (params: StateRepoRequestType = {}, config = {}): Promise<StatisticsSiteSearchKeyword[]> {
        if (this.useSampleData()) {
            return this.demoStatsRepoService.getSiteSearchNoResultsKeywords();
        }
        params = this.setGroupId(params);
        return this.apiService.get(`domains/${this.getDomainId(params.domain_id)}/statistics/site_search/no_results_keywords`, params,
            {
                headers: {
                    noParseSearch: 'true',
                    ...config,
                },
            });
    }

    private useSampleData (): boolean {
        const statistics = this.sessionService.domain !== null && this.sessionService.domain.features.statistics;
        return !statistics && this.customerHasPlanPipe.transform('demo');
    }

    private toEntryPageModels (models: MonTableCollection<StatisticsPage>): MonTableCollection<StatisticsPage> {
        for (let i = 0; i < models.length; i++) {
            models[i] = this.toEntryPageModel(models[i]);
        }
        return models;
    }

    private toEntryPageModel (model: StatisticsPage): StatisticsEntryPageModel {
        return new StatisticsEntryPageModel(model);
    }


}
