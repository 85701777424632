(function () {
    'use strict';
    /**
     *
     */
    angular.module('modules.accessibility').factory('AccClarityRemediationService', AccClarityRemediationService);

    AccClarityRemediationService.$inject = ['PDFService', 'gettextCatalog', 'ng2MonEventsService', 'ActiveRegionService', 'ng2MonModalService'];
    /* @ngInject*/
    function AccClarityRemediationService (PDFService, gettextCatalog, ng2MonEventsService, ActiveRegionService, ng2MonModalService) {
        const isInEuRegion = ActiveRegionService.isInRegion('EU');

        return {
            createRemediation: createRemediation,
            createRemediationDialog: createRemediationDialog,
        };

        function createRemediation (pdf) {
            return PDFService.createRemediation(pdf).then(function () {
                ng2MonEventsService.run('remediationCreated', { url: pdf.url, title: pdf.title });
            }, angular.noop);
        }

        function createRemediationDialog (cb) {
            const options = {
                message: getConfirmationMessage(),
                title: '',
                buttons: [
                    {
                        label: isInEuRegion ? gettextCatalog.getString('Close') : gettextCatalog.getString('Cancel'),
                        className: 'btn btn-secondary',
                    },
                ],
            };

            if (!isInEuRegion) {
                options.buttons.push(
                    {
                        label: gettextCatalog.getString('Submit'),
                        className: 'btn btn-primary',
                        callback: cb,
                    },
                );
            }

            ng2MonModalService.customDialog(options).then(() => {}, () => {});
        }

        function getConfirmationMessage () {
            if (!isInEuRegion) {
                return gettextCatalog.getString(
                    'Document remediation for compliance to WCAG 2.1 AA, Section 508, HHS or PDF/UA accessibility standards is done by our affiliate, NetCentric Technologies, Inc.' +
                    'For remediation, we will coordinate with NetCentric to review the document and provide you a fixed price for the remediation. ' +
                    'Upon completion of the work, NetCentric will also provide a compliance report. ' +
                    'NetCentric will let you know if there is any Alternative Text (Alt-Text) required for images that are not self-descriptive or any related additional information needed to remediate the document. ' +
                    'Please note that all contracts for remediation are between you, the Customer, and NetCentric, and subject to NetCentric\'s Terms and Conditions and Privacy Policy.',
                );
            }

            return gettextCatalog.getString(
                'Document remediation for compliance to WCAG 2.1 AA, Section 508, HHS or PDF/UA accessibility standards is done by our affiliate, NetCentric Technologies, Inc. ' +
                'Due to the regulatory framework requirements in your country, we are not able to coordinate document remediation directly for you; ' +
                'however, you are invited to reach out to NetCentric directly for a quote and remediation services at dherr@allyant.com. ' +
                'Please note that all contracts for remediation are between you, the Customer, and NetCentric, and subject to NetCentric\'s Terms and Conditions and Privacy Policy ' +
                'and will result in a transfer of data to third parties and third countries, including but not limited to the United States, Canada, Australia, and Egypt.',
            );
        }
    }
})();
