(function () {
    'use strict';
    angular
        .module('modules.page-performance')
        .service('PagePerformanceAssignedProfileObsService', PagePerformanceAssignedProfileObsService);

    PagePerformanceAssignedProfileObsService.$inject = ['rx', 'PagePerformanceAssignedProfile', '$interval'];

    /* @ngInject*/
    function PagePerformanceAssignedProfileObsService (rx, PagePerformanceAssignedProfile, $interval) {
        var intervalKey = null;
        var service = {
            assignedProfilesCount: 0,
            assignedProfilesObs: new rx.BehaviorSubject([]),
            disposeAssignedProfiles: disposeAssignedProfiles,
            startLiveReload: startLiveReload,
            reloadAssignedProfiles: reloadAssignedProfiles,
        };

        return service;

        function startLiveReload () {
            disposeAssignedProfiles();
            setupWatchers();
        }

        function disposeAssignedProfiles () {
            $interval.cancel(intervalKey);
            intervalKey = null;
            setAssignedProfiles([]);
        }

        function reloadAssignedProfiles () {
            return getAssignedProfiles().then(setAssignedProfiles, angular.noop);
        }

        // PROTECTED

        function setupWatchers () {
            getAssignedProfiles().then(setAssignedProfiles, angular.noop);
            startInterval();
        }

        function setAssignedProfiles (assignedProfiles) {
            service.assignedProfilesCount = assignedProfiles.total || 0;
            service.assignedProfilesObs.onNext(assignedProfiles);
        }

        function getAssignedProfiles () {
            var params = {
                page_size: 1,
                page: 1,
            };
            var config = {
                headers: {
                    noGlobal: true,
                },
            };
            return PagePerformanceAssignedProfile.getAll(params, config);
        }

        function startInterval () {
            intervalKey = $interval(function () {
                getAssignedProfiles().then(setAssignedProfiles, angular.noop);
            }, 300000); // 5 minutes
        }
    }
})();
