(function () {
    'use strict';
    /**
     * @memberof modules.shortcut
     * @ngdoc factory
     * @name ShortcutService
     * @description Shortcut Service
     */
    angular.module('modules.shortcut').factory('ShortcutService', ShortcutService);

    ShortcutService.$inject = ['ng2ShortcutsRepoService', 'rx', '$interval', 'activeFeatures', 'gettextCatalog'];
    /* @ngInject*/
    function ShortcutService (ng2ShortcutsRepoService, rx, $interval, activeFeatures, gettextCatalog) {
        var vm = this;
        vm.shortcutsInterval = null;
        var service = {
            shortcuts: new rx.BehaviorSubject([]),
            getShortcuts: getShortcuts,
            logout: disposeShortcuts,
            getPreSelectedIcons: getPreSelectedIcons,
            getUrlTargets: getUrlTargets,
            clearShortcuts: clearShortcuts,
        };

        return service;
        /**
         * @memberOf ShortcutService
         * @desc Renew Shortcuts collection
         */
        function getShortcuts () {
            var config = {
                headers: {
                    noGlobal: true,
                },
            };

            var params = {
                page_size: 0,
            };
            cancelShortcuts();
            ng2ShortcutsRepoService.getAll(params, config)
                .then(function (shortcuts) {
                    service.shortcuts.onNext(shortcuts);
                }, angular.noop)
                .finally(function () {
                    startShortcutsInterval();
                });
        }

        /**
         * @memberOf ShortcutService
         * @desc Remove Shortcut collection
         */
        function clearShortcuts () {
            service.shortcuts.onNext([]);
        }

        /**
         * @memberOf ShortcutService
         * @desc Returns a collection of icons supported by the Shortcut Service
         */
        function getPreSelectedIcons () {
            return [
                {
                    name: gettextCatalog.getString('Document'),
                    icon: 'fas fa-file-invoice',
                    value: 'document',
                },
                {
                    name: gettextCatalog.getString('Profile'),
                    icon: 'fas fa-address-card',
                    value: 'profile',
                },
                {
                    name: gettextCatalog.getString('Search Engine'),
                    icon: 'fab fa-searchengin',
                    value: 'search-engine',
                },
                {
                    name: gettextCatalog.getString('Link'),
                    icon: 'fas fa-link',
                    value: 'link',
                },
                {
                    name: gettextCatalog.getString('Chart'),
                    icon: 'fas fa-chart-line',
                    value: 'chart-line',
                },
                {
                    name: gettextCatalog.getString('Home'),
                    icon: 'fas fa-home',
                    value: 'home',
                },
                {
                    name: gettextCatalog.getString('Browser'),
                    icon: 'fas fa-browser',
                    value: 'browser',
                },
                {
                    name: gettextCatalog.getString('Backpack'),
                    icon: 'fas fa-backpack',
                    value: 'backpack',
                },
                {
                    name: gettextCatalog.getString('Smile'),
                    icon: 'fas fa-smile',
                    value: 'smile',
                },
                {
                    name: gettextCatalog.getString('Calendar'),
                    icon: 'fas fa-calendar',
                    value: 'calendar',
                },
                {
                    name: gettextCatalog.getString('User'),
                    icon: 'fas fa-user',
                    value: 'user',
                },
                {
                    name: gettextCatalog.getString('User clock'),
                    icon: 'fas fa-user-clock',
                    value: 'user-clock',
                },
                {
                    name: gettextCatalog.getString('Clock'),
                    icon: 'fas fa-clock',
                    value: 'clock',
                },
                {
                    name: gettextCatalog.getString('Database'),
                    icon: 'fas fa-database',
                    value: 'database',
                },
                {
                    name: gettextCatalog.getString('Barcode'),
                    icon: 'fas fa-barcode-alt',
                    value: 'barcode',
                },
            ];
        }

        function getUrlTargets () {
            return [
                {
                    name: gettextCatalog.getString('New tab'),
                    value: '_blank',
                },
                {
                    name: gettextCatalog.getString('Same tab'),
                    value: '_self',
                },
            ];
        }

        // PROTECTED

        /**
         * @memberOf ShortcutService
         * @desc Dispose the shortcut observable
         */
        function disposeShortcuts () {
            if (!service.shortcuts.isDisposed) {
                service.shortcuts.dispose();
                cancelShortcuts();
            }
        }

        /**
         * @memberOf ShortcutService
         * @desc Start the shortcuts interval
         */
        function startShortcutsInterval () {
            if (vm.shortcutsInterval !== null) {
                cancelShortcuts();
            }
            vm.shortcutsInterval = $interval(function () {
                getShortcuts();
            }, 900000); // 15 Minutes
        }

        /**
         * @memberOf ShortcutService
         * @desc Cancel the shortcuts interval and set it to null
         */
        function cancelShortcuts () {
            if (vm.shortcutsInterval !== null) {
                $interval.cancel(vm.shortcutsInterval);
                vm.shortcutsInterval = null;
            }
        }
    }
})();
