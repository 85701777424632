import colors from '@monsido/colors/dist/es6/colors';
import { hexToRgba } from '../../helpers/hex-to-rgba';

(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsExitPages', {
        templateUrl: 'app/pages/statistics/pages/exit-pages/exit-pages.html',
        controller: StatisticsExitPagesController,
        controllerAs: 'vm',
    });

    StatisticsExitPagesController.$inject = [
        'ng2StatsRepoService',
        '$controller',
        '$stateParams',
        'ParamService',
        '$filter',
        'gettextCatalog',
        'Lodash',
        'moment',
        'ng2SessionService',
        'dateRangeService',
        'ng2MonModalService',
        'ng2MonEventsService',
        'ng2StatisticsDateComparisonService',
        'DATE_FORMAT',
    ];
    /* @ngInject */
    function StatisticsExitPagesController (
        ng2StatsRepoService,
        $controller,
        $stateParams,
        ParamService,
        $filter,
        gettextCatalog,
        Lodash,
        moment,
        ng2SessionService,
        dateRangeService,
        ng2MonModalService,
        ng2MonEventsService,
        ng2StatisticsDateComparisonService,
        DATE_FORMAT,
    ) {
        const vm = this;
        const MAX_PAGE_SIZE_FOR_COMPARISON = 10;
        vm.$onInit = activate;
        vm.updateDateRange = updateDateRange;
        vm.average = average;
        vm.getPage = getPage;
        vm.onSortPageLocal = onSortPageLocal;
        vm.onPageChangeLocal = onPageChangeLocal;
        const dateFormat = 'YYYY-MM-DD';
        vm.validateDate = ng2StatisticsDateComparisonService.validateDate;
        vm.onComparisonRangeSelected = onComparisonRangeSelected;
        vm.updateCompareDate = updateCompareDate;
        vm.comparisonEnabled = false;
        vm.compareDate = { startDate: null, endDate: null };
        vm.noPagesMessage = gettextCatalog.getString('No pages found');
        vm.noPagesSuggestionMessage = gettextCatalog.getString(
            'No pages were found. Try searching for the page URL instead of the page title.',
        );
        vm.noPagesResultMessage = vm.noPagesMessage;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.currentSort = vm.currentSort || {
                by: 'visits',
                direction: 'desc',
            };
            vm.onPageSizeChange = onPageSizeChange;
            vm.domain = ng2SessionService.domain;
            vm.totalVisits = 0;
            vm.summary = {};
            vm.chartSeries = [gettextCatalog.getString('Visits')];
            vm.chartLabels = [];
            vm.chartData = [];
            vm.chartOverride = [];
            vm.onSearch = onSearch;
            vm.search = '';
            vm.date = dateRangeService.setRangeDates();
            vm.dateFormat = DATE_FORMAT;
            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export'),
                    data: {
                        category: 'entry_exit_exit',
                    },
                },
            ];

            setupBarChartOptions();
        }

        function setNoResultMessage () {
            if (!vm.pages.length && !vm.search) {
                vm.noPagesResultMessage = vm.noPagesMessage;
            }

            if (!vm.pages.length && vm.search) {
                vm.noPagesResultMessage = vm.noPagesSuggestionMessage;
            }
        }

        function setupBarChartOptions () {
            vm.chartOptions = {
                legend: {
                    display: false,
                    position: 'bottom',
                    fullWidth: true,
                    labels: {
                        boxWidth: 20,
                        padding: 20,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                callback: function (value) {
                                    return $filter('numeraljs')(value, '0,0');
                                },
                                min: 0,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                callback: function (value) {
                                    return Lodash.truncate(value, 40);
                                },
                            },
                        },
                    ],
                },
                tooltips: {
                    intersect: false,
                },
            };
        }

        function getPage () {
            vm.loading = true;
            vm.promise = Promise.all([getExitPages(), getSummary()])
                .finally(() => {
                    vm.loading = false;
                });
        }

        function onSearch (search) {
            vm.search = search;
            vm.page = 1;
            if (vm.comparisonEnabled && vm.date && vm.compareDate) {
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            } else {
                getPage();
            }
        }

        function onSortPageLocal ({ direction, by }) {
            vm.page = 1;
            vm.currentSort = { by: by, direction: direction };
            ng2MonEventsService.run('actionTrigger', { action: 'table-sort', params: { by: by, direction: direction } });
            if (vm.comparisonEnabled) {
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            } else {
                getPage();
            }
        }

        function getExitPages () {
            const params = {
                limit: vm.pageSize,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.date.startDate?.format(dateFormat),
                to: vm.date.endDate?.format(dateFormat),
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                search: vm.search,
            };

            vm.promise = ng2StatsRepoService.getExitPages(params, { headers: { noParseSearch: true } }).then(function (data) {
                vm.pages = data;
                vm.pages.currentPage = vm.page;
                vm.totalVisits = data.reduce(function (total, page) {
                    return total + parseInt(page.visits);
                }, 0);
                vm.chartLabels = vm.pages.map(function (page) {
                    return page.title ? page.title : page.label;
                });
                vm.chartData = [
                    vm.pages.map(function (page) {
                        return page.visits;
                    }),
                ];
                vm.chartOverride = [
                    {
                        borderWidth: 1,
                        borderColor: [],
                        backgroundColor: [],
                    },
                ];
                for (let i = 0; i < vm.pages.length; i++) {
                    vm.chartOverride[0].borderColor.push(colors['main-1']);
                    vm.chartOverride[0].backgroundColor.push(colors['main-1']);
                }

                setNoResultMessage();

            }, angular.noop);
            return vm.promise;
        }

        async function onComparisonRangeSelected (currentStartDate, currentEndDate, compareStartDate, compareEndDate) {
            vm.loading = true;
            const currentDataRequestParams = {
                limit: MAX_PAGE_SIZE_FOR_COMPARISON,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: currentStartDate?.format(dateFormat),
                to: currentEndDate?.format(dateFormat),
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                search: vm.search,
            };
            const compareDataRequestParams = {
                limit: MAX_PAGE_SIZE_FOR_COMPARISON,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: compareStartDate?.format(dateFormat),
                to: compareEndDate?.format(dateFormat),
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                search: vm.search,
            };

            const [currentPages, comparePages] = await Promise.all([
                ng2StatsRepoService.getExitPages(currentDataRequestParams, { headers: { noParseSearch: true } }),
                ng2StatsRepoService.getExitPages(compareDataRequestParams, { headers: { noParseSearch: true } }),
            ]);

            const currentPagesMap = new Map();

            for (let i = 0; i < currentPages.length; i++) {
                const page = currentPages[i];
                const pageCompare = page.compare ? page.compare : null;
                const computedPage = {
                    ...page,
                    compare: {
                        ...(pageCompare ? {
                            visits: pageCompare.visits,
                            exit_visits: pageCompare.exit_visits,
                            avg_time: pageCompare.avg_time,
                        } : {
                            visits: 0,
                            exit_visits: 0,
                            avg_time: 0,
                        }),
                    },
                };
                currentPagesMap.set(page.label, computedPage);
            }

            for (let i = 0; i < comparePages.length; i++) {
                const comparePage = comparePages[i];
                const existedPageInMap = currentPagesMap.get(comparePage.label);

                if (existedPageInMap) {
                    existedPageInMap.compare = comparePage;
                }
            }

            const computedPages = Array.from(currentPagesMap.values());
            vm.pages = computedPages;
            vm.chartSeries = [
                gettextCatalog.getString('Visits'),
                gettextCatalog.getString('Visits (comp.)'),
            ];
            vm.chartData = [[], []];
            vm.chartOverride = [
                {
                    borderWidth: 1,
                    borderColor: [],
                    backgroundColor: [],
                },
                {
                    borderWidth: 1,
                    borderColor: [],
                    backgroundColor: [],
                },
            ];
            vm.chartLabels = [];
            for (let i = 0; i < computedPages.length; i++) {
                const page = computedPages[i];
                vm.chartData[0].push(page.visits || 0);
                vm.chartData[1].push(page.compare.visits || 0);

                vm.chartLabels.push(page.title || page.label);

                vm.chartOverride[0].borderColor.push(colors['main-1']);
                vm.chartOverride[0].backgroundColor.push(colors['main-1']);
                vm.chartOverride[1].borderColor.push(hexToRgba(colors['main-1'], 0.25));
                vm.chartOverride[1].backgroundColor.push(hexToRgba(colors['main-1'], 0.25));
            }

            setNoResultMessage();
            vm.loading = false;
        }

        function updateDateRange () {
            if (vm.date.startDate && vm.date.endDate) {
                ParamService.setParams({
                    from: moment(vm.date.startDate),
                    to: moment(vm.date.endDate),
                });
            }

            setExportCategoryRef();
            getPage();
        }

        function average (array, prop) {
            if (!Array.isArray(array) || array.length === 0) {
                return 0;
            }
            const total = array.reduce((sum, item) => sum + parseInt(item[prop]), 0);
            return total / array.length;
        }

        function getSummary () {
            const params = {
                domainId: $stateParams.domainId,
                from: vm.date.startDate?.format(dateFormat),
                to: vm.date.endDate?.format(dateFormat),
                type: 'total',
            };

            return ng2StatsRepoService.getSummary(params).then(function (data) {
                vm.summary = data;
            }, angular.noop);
        }

        function updateCompareDate (e) {
            const isFutureDates = ng2StatisticsDateComparisonService.isFutureDate(e.startDate) ||
                ng2StatisticsDateComparisonService.isFutureDate(e.endDate);

            vm.comparisonEnabled = Boolean(e.startDate && e.endDate);

            if (!vm.comparisonEnabled && vm.chartData?.length > 1) {
                vm.chartData.pop();
            }

            if (isFutureDates) {
                return ng2MonModalService.alert(
                    gettextCatalog.getString(
                        'The start and end date cannot be in the future, please enter the compare dates again',
                    ),
                );
            }

            if (vm.comparisonEnabled) {
                vm.compareDate.startDate = e.startDate;
                vm.compareDate.endDate = e.endDate;
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            } else {
                getPage();
            }
        }

        function onPageChangeLocal (page) {
            vm.page = page;
            if (vm.comparisonEnabled) {
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            } else {
                getPage();
            }
        }

        function onPageSizeChange (size) {
            vm.page = 1;
            vm.pageSize = size;
            setExportCategoryRef();
            getPage();
        }

        function setExportCategoryRef () {
            vm.exports[0].data.category_ref = vm.date.startDate?.format(dateFormat) + ',' + vm.date.endDate?.format(dateFormat);
        }
    }
})();
