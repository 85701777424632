(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name PDFService
     * @description Service to handle Accessibility PDF request
     */
    angular.module('services.entities').factory('PDFService', PDFService);

    PDFService.$inject = ['ng2PdfRepoService', 'ng2DemoHasAccessService', 'ng2DemoPdfRepoService'];
    /* @ngInject*/
    function PDFService (ng2PdfRepoService, ng2DemoHasAccessService, ng2DemoPdfRepoService) {
        return {
            get: get,
            update: update,
            createClarity: createClarity,
            createRemediation: createRemediation,
            createAllClarity: createAllClarity,
            markAllPdfsAsReviewed: markAllPdfsAsReviewed,
            requestRemeditionQuoteForAllPdfs: requestRemeditionQuoteForAllPdfs,
        };

        /**
         * @memberOf PDFService
         * @desc Get PDF collection
         * @param {object} params - The parameters to inject to the get
         * @param {object} config - The configuration
         * @returns {Promise} - Promise containing the PDF collection
         */
        function get (params, config) {
            if (ng2DemoHasAccessService.canAccessAccessibilityPDF()) {
                return ng2DemoPdfRepoService.get(params);
            }

            return ng2PdfRepoService.get(params, config);
        }

        /**
         * @memberOf PDFService
         * @desc Update PDF object
         * @param {object} pdf - Document object
         * @param {object} params - The parameters to inject
         * @returns {Promise} - Promise containing the updated PDF Document
         */
        function update (pdf, params) {
            if (ng2DemoHasAccessService.canAccessAccessibilityPDF()) {
                return ng2DemoPdfRepoService.update(pdf, params);
            }

            return ng2PdfRepoService.update(pdf, params);
        }

        /**
         * @memberOf PDFService
         * @desc Update PDF object
         * @param {object} pdf - PDF to request
         * @param {object} params - The parameters to inject
         * @param {object} config - The configuration
         * @returns {Promise}
         */
        function createClarity (pdf) {
            return ng2PdfRepoService.createClarity(pdf);
        }

        /**
         * @memberOf PDFService
         * @desc Update PDF object
         * @param {object} pdf - PDF to request
         * @param {object} data - The data parameters to post
         * @param {object} params - The parameters to inject
         * @param {object} config - The configuration
         * @returns {Promise}
         */
        function createRemediation (pdf) {
            return ng2PdfRepoService.createRemediation(pdf);
        }

        /**
         * @memberOf PDFService
         * @desc Request All Clarity Checks
         *
         * @returns {Promise}
         */
        function createAllClarity () {
            return ng2PdfRepoService.createAllClarity();
        }

        /**
         * @memberOf PDFService
         * @desc Mark All PDFs as reviewed
         * @returns {Promise}
         */
        function markAllPdfsAsReviewed () {
            return ng2PdfRepoService.markAllPdfsAsReviewed();
        }

        /**
         * @memberOf PDFService
         * @desc Request Remediation quote for all PDFs
         * @returns {Promise}
         */
        function requestRemeditionQuoteForAllPdfs () {
            return ng2PdfRepoService.requestRemeditionQuoteForAllPdfs();
        }
    }
})();
