import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { User } from '@monsido/ng2/modules/models/api/user';
import { UserInterface } from '@monsido/ng2/modules/models/api/interfaces/user.interface';
import { DomainInterface } from '@monsido/ng2/modules/models/api/interfaces/domain.interface';
import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { Report } from '@monsido/modules/models/api/report';
import { RequestHeadersType } from '@monsido/ng2/external/http/options';

@Injectable({
    providedIn: 'root',
})
export class MeRepoService {

    constructor (
        private apiService: ApiService,
    ) { }

    async update (data: UserInterface): Promise<User> {
        const modelData = await this.apiService.patch<UserInterface>('me', data);
        return new User(modelData);
    }

    async get (headers?: RequestHeadersType): Promise<User> {
        const modelData = await this.apiService.get<UserInterface>('me', {}, headers);
        return new User(modelData);
    }

    createReport (params): Promise<Report> {
        return this.apiService.post('me/reports', params);
    }

    updateReport (reportId, data): Promise<Report> {
        return this.apiService.patch('me/reports/' + reportId, data);
    }

    deleteReport (reportId): Promise<void> {
        return this.apiService.delete(`me/reports/${reportId}`);
    }

    async updateDomain (domainId, data): Promise<Domain> {
        const modelData = await this.apiService.patch<DomainInterface>(`/me/domains/${domainId}`, data);
        return new Domain(modelData);
    }

    getExports <T = unknown> (params: { page: string, pageSize: string }): Promise<T> {
        return this.apiService.get<T>('me/exports', params);
    }
}
