(function () {
    'use strict';

    angular.module('pages.domain').component('domainDashboardHeartbeat', {
        templateUrl: 'app/pages/domain/charts/heartbeat/heartbeat.html',
        controller: HeartbeatController,
        controllerAs: 'vm',
        bindings: {
            historyData: '=',
        },
    });

    HeartbeatController.$inject = ['$filter', 'gettextCatalog', 'ng2UptimeRepoService', 'moment', '$q'];
    /* @ngInject */
    function HeartbeatController ($filter, gettextCatalog, ng2UptimeRepoService, moment, $q) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.loading = true;
            vm.downTimeList = [];
            vm.domainStatus = false;
            vm.lastDowntime = null;
            vm.uptimePercentage = 0;
            vm.successRate = 0;
            vm.uptime = null;
            vm.doughnutColors = ['#4dc0bd', '#dcdcdc'];
            vm.doughnutLabels = [gettextCatalog.getString('Uptime'), gettextCatalog.getString('Downtime')];
            getUptimes();
        }

        function getUptimes () {
            ng2UptimeRepoService.getAll().then(function (uptimes) {
                var uptime;

                if (Array.isArray(uptimes)) {
                    uptime = uptimes.find(function (uptime) {
                        return uptime.type === 'primary';
                    });
                } else {
                    uptime = null;
                }

                if (uptime === undefined) {
                    uptime = uptimes.length > 0 ? uptimes[0] : null;
                }

                if (uptime !== null) {
                    vm.uptime = uptime;
                    showUptimeData(uptime);
                } else {
                    vm.loading = false;
                    setupDoughnutChart();
                }
            }, angular.noop);
        }

        function showUptimeData (uptime) {
            var params = {
                start_date: moment().subtract(30, 'days')
                    .startOf('day')
                    .format(),
                end_date: moment().endOf('day')
                    .format(),
            };
            var promises = [];
            promises.push(ng2UptimeRepoService.getReport(uptime.id, params, { noGlobal: true }));
            promises.push(ng2UptimeRepoService.getDowntimes(uptime.id, params));

            $q.all(promises)
                .then(function (values) {
                    // If no pings have ever been made, the status is set to INACTIVE instead of DOWN
                    if (values[0].data.length > 0) {
                        vm.domainStatus = values[0].last_result ? 'OK' : 'DOWN';
                    } else {
                        vm.domainStatus = 'INACTIVE';
                    }
                    vm.uptimePercentage = $filter('monNumber')(parseFloat(values[0].success_rate), '0[.00]');
                    vm.successRate = values[0].success_rate;

                    vm.downTimeList = values[1]
                        .map(function (uptime) {
                            uptime.start_at = new Date(uptime.start_at);
                            uptime.end_at = new Date(uptime.end_at);
                            return uptime;
                        })
                        .sort(function (a, b) {
                            return a.start_at > b.start_at ? -1 : a.start_at < b.start_at ? 1 : 0;
                        });
                    vm.lastDowntime = vm.downTimeList.length > 0 ? vm.downTimeList[0] : null;
                }, angular.noop)
                .finally(function () {
                    setupDoughnutChart();
                    vm.loading = false;
                });
        }

        function setupDoughnutChart () {
            vm.doughnutData = [vm.successRate, 100 - vm.successRate];
            vm.doughnutOptions = {
                centerText: {
                    percentageLabel: setPercentage(vm.successRate),
                    totalLabel: gettextCatalog.getString('Uptime last 30 days'),
                    totalFontSize: 150,
                    subFontSize: 150,
                },
                cutoutPercentage: 80,
                tooltips: {
                    enabled: false,
                },
                hover: {
                    mode: null,
                },
            };
        }

        function setPercentage (percentage) {
            // To prevent the doughnut from showing 100% when it's not actually 100%
            var newPercentage = angular.copy(percentage);
            if (newPercentage > 99) {
                newPercentage = Math.floor(newPercentage * 100) / 100;
            } else if (percentage < 1) {
                newPercentage = Math.ceil(newPercentage * 100) / 100;
            }
            return $filter('monNumber')(newPercentage / 100, '0.[00] %');
        }
    }
})();
