import { STATISTICS, MAX_PAGE_SIZE_FOR_COMPARISON, MAX_ITEM_COUNT_FOR_EXPORT } from '@monsido/modules/statistics/statistics.constant';

(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsLeastPopularPages', {
        templateUrl: 'app/pages/statistics/pages/least-popular/least-popular-pages.html',
        controller: StatisticsLeastPopularPagesController,
        controllerAs: 'vm',
    });

    StatisticsLeastPopularPagesController.$inject = [
        'ng2StatsRepoService',
        '$controller',
        '$stateParams',
        'ParamService',
        'gettextCatalog',
        'ng2SessionService',
        'dateRangeService',
        'ng2MonModalService',
        'moment',
        'ng2StatisticsDateComparisonService',
        'DATE_FORMAT',
    ];
    /* @ngInject */
    function StatisticsLeastPopularPagesController (
        ng2StatsRepoService,
        $controller,
        $stateParams,
        ParamService,
        gettextCatalog,
        ng2SessionService,
        dateRangeService,
        ng2MonModalService,
        moment,
        ng2StatisticsDateComparisonService,
        DATE_FORMAT,
    ) {
        const vm = this;
        const dateFormat = STATISTICS.DATE_FORMAT;
        vm.validateDate = ng2StatisticsDateComparisonService.validateDate;
        vm.onComparisonRangeSelected = onComparisonRangeSelected;
        vm.updateCompareDate = updateCompareDate;
        vm.updateDateRange = updateDateRange;
        vm.average = average;
        vm.getPage = getPage;
        vm.$onInit = activate;
        vm.noPagesShortTxt = gettextCatalog.getString('No pages found');
        vm.noPagesLongTxt = gettextCatalog.getString(
            'No pages were found. Try searching for the page URL instead of the page title.',
        );
        vm.noPagesFoundMessage = vm.noPagesShortTxt;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.onPageChange = onPageChange;
            vm.onPageSizeChange = onPageSizeChange;
            vm.onSearch = onSearch;
            vm.comparisonEnabled = false;
            vm.compareDate = { startDate: null, endDate: null };
            vm.domain = ng2SessionService.domain;
            vm.totalVisits = 0;
            vm.summary = {};
            vm.date = dateRangeService.setRangeDates();
            vm.dateFormat = DATE_FORMAT;
            vm.search = '';
            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export'),
                    note: {
                        message: gettextCatalog.getString(`We export up to a maximum of ${MAX_ITEM_COUNT_FOR_EXPORT} rows`),
                    },
                    data: {
                        category: 'least_popular_pages',
                        date: vm.date,
                    },
                },
            ];
            vm.loading = false;

            getSummary();
        }

        function getPage () {
            const params = {
                limit: vm.pageSize,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: vm.date.startDate?.format(dateFormat),
                to: vm.date.endDate?.format(dateFormat),
                search: vm.search,
            };

            vm.loading = true;

            vm.promise = Promise.all([ng2StatsRepoService.getLeastPopularPages(params, { headers: { noParseSearch: true } })
                .then(function (data) {
                    vm.pages = data;
                    vm.pages.currentPage = vm.page;
                    vm.totalVisits = data.reduce(function (total, page) {
                        return total + parseInt(page.visits);
                    }, 0);

                    if (!vm.pages.length) {
                        vm.noPagesFoundMessage = getNoSearchResultMessage();
                    }

                }, angular.noop), getSummary()])
                .finally(() => vm.loading = false);
        }

        async function onComparisonRangeSelected (currentStartDate, currentEndDate, compareStartDate, compareEndDate) {
            vm.loading = true;
            const currentDataRequestParams = {
                limit: MAX_PAGE_SIZE_FOR_COMPARISON,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: currentStartDate?.format(dateFormat),
                to: currentEndDate?.format(dateFormat),
                search: vm.search,
            };
            const compareDataRequestParams = {
                limit: MAX_PAGE_SIZE_FOR_COMPARISON,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                from: compareStartDate?.format(dateFormat),
                to: compareEndDate?.format(dateFormat),
                search: vm.search,
            };

            const [currentPages, comparePages] = await Promise.all([
                ng2StatsRepoService.getLeastPopularPages(currentDataRequestParams, { headers: { noParseSearch: true } }),
                ng2StatsRepoService.getLeastPopularPages(compareDataRequestParams, { headers: { noParseSearch: true } }),
                getSummary(),
            ]);

            const currentPagesMap = new Map();

            for (let i = 0; i < currentPages.length; i++) {
                const currentPage = currentPages[i];
                const pageCompare = currentPage.compare ? currentPage.compare : null;
                const computedPage = {
                    ...currentPage,
                    compare: {
                        ...(pageCompare ? {
                            visits: pageCompare.visits,
                            pageviews: pageCompare.pageviews,
                            avg_time: pageCompare.avg_time,
                        } : {
                            visits: 0,
                            pageviews: 0,
                            avg_time: 0,
                        }),
                    },
                };
                currentPagesMap.set(currentPage.label, computedPage);
            }

            for (let i = 0; i < comparePages.length; i++) {
                const comparePage = comparePages[i];
                const existedPageInMap = currentPagesMap.get(comparePage.label);

                if (existedPageInMap) {
                    existedPageInMap.compare = comparePage;
                }
            }

            if (!vm.pages.length) {
                vm.noPagesFoundMessage = getNoSearchResultMessage();
            }

            vm.pages = Array.from(currentPagesMap.values());
            vm.loading = false;
        }

        function onSearch (search) {
            vm.search = search;
            vm.page = 1;
            if (vm.comparisonEnabled && vm.date && vm.compareDate) {
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            } else {
                getPage();
            }
        }

        function updateDateRange () {
            vm.exports[0].data.date.startDate = vm.date.startDate;
            vm.exports[0].data.date.endDate = vm.date.endDate;
            if (vm.date.startDate && vm.date.endDate) {
                ParamService.setParams({
                    from: moment(vm.date.startDate),
                    to: moment(vm.date.endDate),
                });
            }

            setExportCategoryRef();
            getPage();
        }

        function updateCompareDate (e) {
            const isFutureDates = ng2StatisticsDateComparisonService.isFutureDate(e.startDate) ||
                ng2StatisticsDateComparisonService.isFutureDate(e.endDate);

            if (isFutureDates) {
                return ng2MonModalService.alert(
                    gettextCatalog.getString(
                        'The start and end date cannot be in the future, please enter the compare dates again',
                    ),
                );
            }

            vm.comparisonEnabled = e.startDate && e.endDate;

            if (vm.comparisonEnabled) {
                vm.compareDate.startDate = e.startDate;
                vm.compareDate.endDate = e.endDate;

                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            }
        }

        function average (array, prop) {
            if (!Array.isArray(array) || array.length === 0) {
                return 0;
            }
            const total = array.reduce((sum, item) => sum + parseInt(item[prop]), 0);
            return total / array.length;
        }

        function getSummary () {
            const params = {
                domainId: $stateParams.domainId,
                from: vm.date.startDate?.format(dateFormat),
                to: vm.date.endDate?.format(dateFormat),
                type: 'total',
            };
            return ng2StatsRepoService.getSummary(params)
                .then(function (data) {
                    vm.summary = data;
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function getNoSearchResultMessage () {
            return vm.search ? vm.noPagesLongTxt : vm.noPagesShortTxt;
        }

        function onPageChange (page) {
            vm.page = page;
            if (vm.comparisonEnabled) {
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            } else {
                getPage();
            }
        }

        function onPageSizeChange (pageSize) {
            vm.pageSize = pageSize;
            vm.page = 1;
            setExportCategoryRef();
            vm.getPage();
        }

        function setExportCategoryRef () {
            vm.exports[0].data.category_ref = MAX_ITEM_COUNT_FOR_EXPORT;
        }
    }
})();
