import { Injectable } from '@angular/core';
import { ApiService } from '../api-service/api.service';
import { SessionService } from '@monsido/ng2/core/session/session.service';
import { DowntimeType, UptimeReportType, UptimeType } from './uptime.type';
import { RequestHeadersType, RequestParamsType } from '@monsido/ng2/external/http/options';
import { BaseRepo, BaseRepoRequestType } from '../base-repo/base-repo';

type UptimeRepoRequestType = RequestParamsType & BaseRepoRequestType;
@Injectable({
    providedIn: 'root',
})
export class UptimeRepoService extends BaseRepo {
    constructor (private apiService: ApiService, protected sessionService: SessionService) {
        super();
    }

    getAll (params: UptimeRepoRequestType = {}, domainId?: number): Promise<UptimeType[]> {
        params = this.setGroupId(params);
        domainId = domainId ?? this.getDomainId(params.domain_id);
        if (!domainId) {
            // eslint-disable-next-line no-console
            console.warn('No domain id was provided!');
            return Promise.resolve([]);
        }
        return this.apiService.get('domains/' + domainId + '/uptimes', params);
    }

    getReport (id: number, params: UptimeRepoRequestType = {}, headers?: RequestHeadersType): Promise<UptimeReportType> {
        params = this.setGroupId(params);
        return this.apiService.get(
            'domains/' + this.getDomainId(params.domain_id) + '/uptimes/' + id + '/report',
            params,
            headers,
        );
    }

    getDowntimes (id: number, params: UptimeRepoRequestType = {}): Promise<DowntimeType[]> {
        params = this.setGroupId(params);
        return this.apiService.get(
            'domains/' + this.getDomainId(params.domain_id) + '/uptimes/' + id + '/downtimes',
            params,
        );
    }

    get (id: number, params: UptimeRepoRequestType = {}, headers: RequestHeadersType = {}): Promise<UptimeType[]> {
        params = this.setGroupId(params);
        return this.apiService.get(
            'domains/' + this.getDomainId(params.domain_id) + '/uptimes/' + id,
            params,
            headers,
        );
    }

    destroy (id: number): Promise<void> {
        return this.apiService.delete('domains/' + this.getDomainId() + '/uptimes/' + id);
    }

    create (data: unknown): Promise<void> {
        return this.apiService.post(
            'domains/' + this.getDomainId() + '/uptimes',
            data,
        );
    }

    update (data: Partial<UptimeReportType> & { id: number }): Promise<void> {
        return this.apiService.patch(
            'domains/' + this.getDomainId() + '/uptimes/' + data.id,
            data,
        );
    }

    getAllScheduledDowntimes (uptimeId: number, params: UptimeRepoRequestType = {}): Promise<DowntimeType[]> {
        params = this.setGroupId(params);
        return this.apiService.get(
            '/domains/' + this.getDomainId(params.domain_id) + '/uptimes/' + uptimeId + '/scheduled_downtimes',
            params,
        );
    }

    createScheduledDowntime (uptimeId: number, data: unknown): Promise<void> {
        return this.apiService.post(
            '/domains/' + this.getDomainId() + '/uptimes/' + uptimeId + '/scheduled_downtimes',
            data,
        );
    }

    updateScheduledDowntime (uptimeId: number, id: number, data: Partial<DowntimeType>): Promise<void> {
        return this.apiService.patch(
            '/domains/' + this.getDomainId() + '/uptimes/' + uptimeId + '/scheduled_downtimes/' + id,
            data,
        );
    }

    destroyScheduledDowntime (uptimeId: number, id: number): Promise<void> {
        return this.apiService.delete(
            '/domains/' + this.getDomainId() + '/uptimes/' + uptimeId + '/scheduled_downtimes/' + id,
        );
    }
}
