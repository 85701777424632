import colors from '@monsido/colors/dist/es6/colors';
import { hexToRgba } from '../../helpers/hex-to-rgba';
import { MAX_PAGE_SIZE_FOR_COMPARISON, MAX_ITEM_COUNT_FOR_EXPORT } from '@monsido/modules/statistics/statistics.constant';

(function () {
    'use strict';

    angular.module('pages.statistics').component('statisticsMostDownloads', {
        templateUrl: 'app/pages/statistics/pages/most-downloads/most-downloads.html',
        controller: StatisticsMostDownloadsController,
        controllerAs: 'vm',
    });

    StatisticsMostDownloadsController.$inject = [
        'ng2StatsRepoService',
        '$stateParams',
        'gettextCatalog',
        '$filter',
        '$controller',
        'Lodash',
        'ng2SessionService',
        'dateRangeService',
        'ParamService',
        'ng2StatisticsDateComparisonService',
        'DATE_FORMAT',
    ];
    /* @ngInject */
    function StatisticsMostDownloadsController (
        ng2StatsRepoService,
        $stateParams,
        gettextCatalog,
        $filter,
        $controller,
        Lodash,
        sessionService,
        dateRangeService,
        ParamService,
        ng2StatisticsDateComparisonService,
        DATE_FORMAT,
    ) {
        const vm = this;
        vm.getPage = getPage;
        vm.updateDateRange = updateDateRange;
        vm.$onInit = activate;
        vm.validateDate = ng2StatisticsDateComparisonService.validateDate;
        vm.onComparisonRangeSelected = onComparisonRangeSelected;
        vm.updateCompareDate = updateCompareDate;
        vm.onPageChangeLocal = onPageChangeLocal;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            angular.extend(vm, $controller('BasePagesController', { vm: vm }));
            vm.onPageSizeChange = onPageSizeChange;
            vm.onSearch = onSearch;
            vm.downloads = [];
            vm.domain = sessionService.domain;
            vm.totalUniqueDownloads = 0;
            vm.summary = {};
            vm.chartSeries = [gettextCatalog.getString('Total unique downloads')];
            vm.chartLabels = [];
            vm.chartData = [];
            vm.chartOverride = [];
            vm.compareDate = { startDate: null, endDate: null };
            vm.search = '';
            vm.noResultsMsg = gettextCatalog.getString('No downloads found');
            vm.noDownloadsResultMessage = vm.noResultsMsg;
            vm.noResultsMsgSuggestion = gettextCatalog.getString(
                'No files were found. Try searching for the file URL instead of the file title.',
            );
            vm.chartOptions = {
                legend: {
                    display: false,
                    position: 'bottom',
                    fullWidth: true,
                    labels: {
                        boxWidth: 20,
                        padding: 20,
                    },
                },
                scales: {
                    xAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                stepSize: 1,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            gridLines: {
                                display: false,
                            },
                            ticks: {
                                callback: function (value) {
                                    return Lodash.truncate(value, 40);
                                },
                            },
                        },
                    ],
                },
                tooltips: {
                    intersect: false,
                },
            };
            vm.loading = false;
            vm.date = dateRangeService.setRangeDates();
            vm.dateFormat = DATE_FORMAT;
            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export'),
                    note: {
                        message: gettextCatalog.getString(`We export up to a maximum of ${MAX_ITEM_COUNT_FOR_EXPORT} rows`),
                    },
                    data: {
                        category: 'most_downloads',
                        date: vm.date,
                    },
                },
            ];
        }

        function onSearch (search) {
            vm.search = search;
            vm.page = 1;
            if (vm.comparisonEnabled && vm.date && vm.compareDate) {
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            } else {
                getPage();
            }
        }

        function getPage () {
            const params = {
                limit: vm.pageSize,
                page: vm.page || 1,
                from: vm.date.startDate?.format('YYYY-MM-DD'),
                to: vm.date.endDate?.format('YYYY-MM-DD'),
                search: vm.search,
            };

            vm.loading = true;

            vm.promise = Promise.all([ng2StatsRepoService.getMostDownloads(params, { headers: { noParseSearch: true } })
                .then((data) => {
                    vm.downloads = data;
                    vm.downloads.currentpage = vm.page;
                    vm.totalUniqueDownloads = data.reduce((total, downloads) => {
                        return total + parseInt(downloads.downloads);
                    }, 0);
                    vm.downloads.sort((a, b) => {
                        return b.downloads - a.downloads;
                    });
                    vm.chartLabels = vm.downloads.map((downloads) => {
                        return downloads.title ? downloads.title : downloads.label;
                    });
                    vm.chartData = [
                        vm.downloads.map((downloads) => {
                            return downloads.downloads;
                        }),
                    ];
                    vm.chartOverride = [
                        {
                            borderWidth: 1,
                            borderColor: [],
                            backgroundColor: [],
                        },
                    ];
                    for (let i = 0; i < vm.downloads.length; i++) {
                        vm.chartOverride[0].borderColor.push(colors['main-1']);
                        vm.chartOverride[0].backgroundColor.push(colors['main-1']);
                    }

                    setNoSearchResultsMsgs(vm.downloads, vm.search);
                }, angular.noop), getSummary()])
                .finally(() => vm.loading = false);
        }

        function getSummary () {
            const params = {
                domainId: $stateParams.domainId,
                from: vm.date.startDate?.format('YYYY-MM-DD'),
                to: vm.date.endDate?.format('YYYY-MM-DD'),
                type: 'total',
            };
            return ng2StatsRepoService.getSummary(params)
                .then(function (data) {
                    vm.summary = data;
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        async function onComparisonRangeSelected (currentStartDate, currentEndDate, compareStartDate, compareEndDate) {
            vm.loading = true;
            const currentDataRequestParams = {
                limit: MAX_PAGE_SIZE_FOR_COMPARISON,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                search: vm.search,
                from: currentStartDate?.format('YYYY-MM-DD'),
                to: currentEndDate?.format('YYYY-MM-DD'),
            };
            const compareDataRequestParams = {
                limit: MAX_PAGE_SIZE_FOR_COMPARISON,
                page: vm.page || 1,
                domainId: $stateParams.domainId,
                search: vm.search,
                from: compareStartDate?.format('YYYY-MM-DD'),
                to: compareEndDate?.format('YYYY-MM-DD'),
            };

            const [currentPages, comparePages] = await Promise.all([
                ng2StatsRepoService.getMostDownloads(currentDataRequestParams, { headers: { noParseSearch: true } }),
                ng2StatsRepoService.getMostDownloads(compareDataRequestParams, { headers: { noParseSearch: true } }),

                getSummary(),
            ]);

            const currentPagesMap = new Map();

            for (let i = 0; i < currentPages.length; i++) {
                const page = currentPages[i];
                const pageCompare = page.compare ? page.compare : null;
                const computedPage = {
                    ...page,
                    compare: {
                        ...(pageCompare ? {
                            downloads: pageCompare.downloads,
                            pageviews: pageCompare.pageviews,
                        } : {
                            downloads: 0,
                            pageviews: 0,
                        }),
                    },
                };
                currentPagesMap.set(page.label, computedPage);
            }

            for (let i = 0; i < comparePages.length; i++) {
                const comparePage = comparePages[i];
                const existedPageInMap = currentPagesMap.get(comparePage.label);
                if (existedPageInMap) {
                    existedPageInMap.compare = comparePage;
                }
            }

            const computedPages = Array.from(currentPagesMap.values());

            vm.downloads = computedPages;
            vm.chartSeries = [
                gettextCatalog.getString('Total unique downloads'),
                gettextCatalog.getString('Total unique downloads (comp.)'),
            ];
            vm.chartData = [[], []];
            vm.chartOverride = [
                {
                    borderWidth: 1,
                    borderColor: [],
                    backgroundColor: [],
                },
                {
                    borderWidth: 1,
                    borderColor: [],
                    backgroundColor: [],
                },
            ];
            vm.chartLabels = [];

            for (let i = 0; i < computedPages.length; i++) {
                const page = computedPages[i];
                vm.chartData[0].push(page.downloads || 0);
                vm.chartData[1].push(page.compare.downloads || 0);

                vm.chartLabels.push(page.title || page.label);

                vm.chartOverride[0].borderColor.push(colors['main-1']);
                vm.chartOverride[0].backgroundColor.push(colors['main-1']);
                vm.chartOverride[1].borderColor.push(hexToRgba(colors['main-1'], 0.25));
                vm.chartOverride[1].backgroundColor.push(hexToRgba(colors['main-1'], 0.25));
            }

            setNoSearchResultsMsgs(vm.downloads, vm.search);
            vm.loading = false;
        }

        function setNoSearchResultsMsgs (results, searchTerm) {
            if (!results.length && !searchTerm) {
                return vm.noDownloadsResultMessage = noResultsMsg;
            }

            if (!results.length && searchTerm) {
                vm.noDownloadsResultMessage = vm.noResultsMsgSuggestion;
            }
        }

        function onPageChangeLocal (page) {
            vm.page = page;
            if (vm.comparisonEnabled) {
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            } else {
                getPage();
                getSummary();
            }
        }

        function onPageSizeChange (pageSize) {
            vm.pageSize = pageSize;
            vm.page = 1;
            setExportCategoryRef();
            getPage();
        }

        function updateCompareDate (e) {
            const isFutureDates = ng2StatisticsDateComparisonService.isFutureDate(e.startDate) ||
                ng2StatisticsDateComparisonService.isFutureDate(e.endDate);

            vm.comparisonEnabled = e.startDate && e.endDate && !isFutureDates;

            if (!vm.comparisonEnabled && vm.chartData?.length > 1) {
                vm.chartData.pop();
            }

            if (isFutureDates) {
                return ng2MonModalService.alert(
                    gettextCatalog.getString(
                        'The start and end date cannot be in the future, please enter the compare dates again',
                    ),
                );
            }

            if (vm.comparisonEnabled) {
                vm.compareDate.startDate = e.startDate;
                vm.compareDate.endDate = e.endDate;
                vm.promise = onComparisonRangeSelected(
                    vm.date.startDate,
                    vm.date.endDate,
                    vm.compareDate.startDate,
                    vm.compareDate.endDate,
                );
            }
        }

        function updateDateRange () {
            vm.exports[0].data.date.startDate = vm.date.startDate;
            vm.exports[0].data.date.endDate = vm.date.endDate;
            if (vm.date.startDate && vm.date.endDate) {
                ParamService.setParams({
                    from: moment(vm.date.startDate),
                    to: moment(vm.date.endDate),
                });
            }

            setExportCategoryRef();
            getPage();
            getSummary();
        }

        function setExportCategoryRef () {
            vm.exports[0].data.category_ref = MAX_ITEM_COUNT_FOR_EXPORT;
        }
    }
})();
