(function () {
    'use strict';

    angular.module('services.api').factory('PageFixRepo', PageFixRepo);

    PageFixRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'PageFixModel'];
    /* @ngInject */
    function PageFixRepo (ng2ApiService, ng2SessionService, PageFixModel) {
        return {
            getAll: getAll,
            destroy: destroy,
            create: create,
            update: update,
            deleteAll: deleteAll,
        };

        // /////////////

        function getAll (params, config) {
            params = params || {};
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/page_fixes', params, config).then(toModels);
        }

        function create (data, params, config) {
            data = data || {};
            params = params || {};
            params = setGroupId(params);
            return ng2ApiService.post('domains/' + ng2SessionService.getDomainId(params) + '/page_fixes', data, params, config).then(toModel);
        }

        function update (pageFix, data, params, config) {
            params = params || {};
            params = setGroupId(params);
            return ng2ApiService.patch('domains/' + ng2SessionService.getDomainId(params) + '/page_fixes/' + pageFix.id, data, params, config).then(
                toModel,
            );
        }

        function destroy (id) {
            return ng2ApiService.delete('domains/' + ng2SessionService.getDomainId() + '/page_fixes/' + id);
        }

        function deleteAll (params, config) {
            params = params || {};
            return ng2ApiService.delete('domains/' + ng2SessionService.getDomainId(params) + '/page_fixes/', params);
        }

        // PROTECTED

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new PageFixModel(model);
        }

        function setGroupId (params) {
            const groupId = ng2SessionService.getGroupId(params);
            if (groupId) {
                params.group_id = groupId;
            }
            return params;
        }
    }
})();
